//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DOMPurify from 'dompurify';
import widget from '@/components/arbitrator/Common/Widget.vue';
import chatImage from '@/views/P2POffersAndDeals/P2PDeals/chatImage.vue';
import constant from '~/const';

export default {
  components: {
    widget,
    chatImage,
  },

  props: {
    messages: { type: Array, default: () => ([]) },
    images: { type: Array, default: () => ([]) },
    loader: { type: Boolean, default: false },
    usernames: { type: Array, default: () => ([]) },
    tradeControl: { type: Boolean, default: false },
    confirm: { type: Function, required: false },
    cancel: { type: Function, required: false },
    edit: { type: Function, required: false },
    appendTrades: { type: Function, required: false },
    status: { type: Number, default: undefined },
  },

  data() {
    return {
      constant,
    };
  },

  computed: {
    getMessagesId() {
      return this.$props.messages[0]?.id;
    },

    getCancelText() {
      switch (this.$props.status) {
        case 6:
          return 'Close dispute';
        default:
          return 'Cancel';
      }
    },
  },

  watch: {
    messages(newVal) {
      if (newVal.length > 0) {
        setTimeout(() => {
          this.$refs.chatBottom.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      }
    },
    immediate: true,
  },

  methods: {
    getCardColor(chatMemberType) {
      const { dark } = this.$vuetify.theme;
      switch (chatMemberType) {
        case this.constant.chat.CHAT_MEMBER_TYPE.CUSTOMER:
          return dark ? '#366F22' : '#E1FEC6';
        case this.constant.chat.CHAT_MEMBER_TYPE.ARBITRATOR:
          return dark ? '#363F48' : '#EDF8E6';
        case this.constant.chat.CHAT_MEMBER_TYPE.SYSTEM:
          return dark ? '#75434D' : '#FFC9D4';
        case this.constant.chat.CHAT_MEMBER_TYPE.ADMIN:
          return dark ? '#097CD7' : '#42A7F8';
        default:
          return 'grey';
      }
    },

    getSide(userName) {
      if (userName) {
        return this.$props.usernames.indexOf(userName);
      }
      return '';
    },

    hasAttach(message) {
      return Boolean(message.files?.[0]);
    },

    getAttachData(message) {
      return this.$props.images?.find((e) => e.id === message.files?.[0]?.id)?.data;
    },

    hasMessages() {
      return this.$props.messages.length;
    },

    sanitize(text) {
      return DOMPurify.sanitize(text, { USE_PROFILES: { html: true } });
    },
  },
};

