//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import widget from '@/components/arbitrator/Common/Widget.vue';

export default {
  components: {
    widget,
  },

  props: {
    user: { type: Object, default: () => ({}) },
    loader: { type: Boolean, default: false },
    initial: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    title: { type: String, default: '' },
  },

  computed: {
    rows() {
      return [
        {
          key: this.$t('Username'),
          value: this.$props.user?.username,
          link: this.api_Type === 'OLD' && this.profile_url + this.$props.user?.username,
        },
        {
          key: this.$t('Rating'),
          value: this.$props.user?.rating,
        },
        {
          key: this.$t('Feedbacks'),
          value: this.$props.user?.totalFeedbacks,
        },
        {
          key: this.$t('Trades'),
          value: this.$props.user?.completedTrades,
        },
      ].filter((e) => {
        return !e.hide;
      });
    },
  },
};
