//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  props: {
    file: { type: [Object, Array], default: () => ({}) },
    isErrorAvailable: { type: Boolean, default: true },
  },

  data() {
    return {
      source: '',
      loading: false,
      showModal: false,
      isZoomActive: false,
      zoom: {
        backgroundPosition: '0% 0%',
      },
      zoomPosition: {
        top: '0px',
        left: '0px',
      },
    };
  },

  computed: {
    getFileName() {
      return this.$props.file.name ?? this.$props.file[0].name ?? this.$props.file[0].Name;
    },

    getFileExtension() {
      return this.$props.file.extension ?? this.$props.file[0].extension ?? this.$props.file[0].Extension;
    },

    zoomStyle() {
      return {
        backgroundImage: `url(${this.source})`,
        backgroundPosition: this.zoom.backgroundPosition,
        backgroundSize: '1000%',
        top: this.zoomPosition.top,
        left: this.zoomPosition.left,
      };
    },
  },

  async mounted() {
    await this.imagePath(this.$props.file);
  },

  methods: {
    ...mapActions('p2pDeals', ['getFile']),

    imageError() {
      this.$emit('error');
    },

    async imagePath(file) {
      if (file[0].data) {
        this.source = file[0].data;
      } else {
        this.loading = true;
        const res = await this.getFile(file.id ?? file[0].id ?? file[0].Id);
        this.source = res.data;
        this.loading = false;
      }
    },

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      this.resetZoom();
    },

    toggleZoom(e) {
      this.isZoomActive = !this.isZoomActive;
      if (this.isZoomActive) {
        this.updateZoomPosition(e);
      }
    },

    handleMouseMove(e) {
      if (this.isZoomActive) {
        this.updateZoomPosition(e);
      }
    },

    updateZoomPosition(e) {
      const {
        offsetX,
        offsetY,
        target,
        clientX,
        clientY,
      } = e;
      const { clientWidth, clientHeight } = target;

      const xPos = (offsetX / clientWidth) * 100;
      const yPos = (offsetY / clientHeight) * 100;

      this.zoom.backgroundPosition = `${xPos}% ${yPos}%`;

      // Позиционирование увеличенного блока
      this.zoomPosition.top = `${clientY - 150}px`;
      this.zoomPosition.left = `${clientX + 25}px`;
    },

    resetZoom() {
      this.isZoomActive = false;
      this.zoom.backgroundPosition = '0% 0%';
    },
  },
};
