//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      theme: this.$vuetify.theme.dark,
    };
  },
};
