//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import widget from '@/components/arbitrator/Common/Widget.vue';
import { mapGetters, mapState } from 'vuex';
import constant from '~/const';

export default {
  components: {
    widget,
  },

  props: {
    trade: { type: Object, default: () => ({}) },
    loader: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    hasTitle: { type: Boolean, default: true },
  },

  data() {
    return {
      constant,
    };
  },

  computed: {
    ...mapGetters('currencies', ['getP2PCurrencyById']),
    ...mapGetters('tradersPaymentMethods', ['getMethodById']),
    ...mapState('currencies', ['p2p_currencies']),
    ...mapState('tradersPaymentMethods', {
      paymentMethods: 'data',
    }),

    rows() {
      const defaultData = [
        {
          key: this.$t('Seller amount'),
          value: `${this.$utils.sciToDec(this.$props.trade?.cryptoSellerAmount)} ${this.getP2PCurrencyById(this.$props.trade?.cryptoSellerCurrencyId)?.title}`,
        },
        {
          key: this.$t('Buyer amount'),
          value: `${this.$utils.sciToDec(this.$props.trade?.cryptoBuyerAmount)} ${this.getP2PCurrencyById(this.$props.trade?.cryptoBuyerCurrencyId)?.title}`,
        },
        {
          key: this.$t('Exchange rate'),
          value: `1 ${this.getP2PCurrencyById(this.$props.trade?.cryptoSellerCurrencyId)?.title} = ${this.$utils.sciToDec(this.$props.trade?.exchangeRate)} ${this.getP2PCurrencyById(this.$props.trade?.cryptoBuyerCurrencyId)?.title}`,
        },
        {
          key: this.$t('Payment method'),
          value: `${this.getMethodById(this.$props.trade?.paymentMethodId)?.name}`,
        },
        {
          key: this.$t('Service fee'),
          value: this.$utils.sciToDec(this.$props.trade?.systemProfit),
        },
        {
          key: this.$t('Deal status'),
          value: this.$t(this.constant.traders.TRADE_STATUS_IOTA[this.$props.trade?.status]),
        },
        {
          key: this.$t('Created at'),
          value: `${this.$utils.getDate(this.$props.trade?.createdAt)} ${this.$utils.getTime(this.$props.trade?.createdAt)}`,
        },
        {
          key: this.$t('Deal ID'),
          value: this.$props.trade?.id,
        },
        {
          key: this.$t('Chat ID'),
          value: this.$props.trade?.conversationId,
        },
        {
          key: this.$t('Advertisement ID'),
          value: this.$props.trade?.advertisementId,
        },
        {
          key: this.$t('Arbitrator call date'),
          value: `${this.$utils.getDate(this.$props.trade?.arbitratorCallDate)} ${this.$utils.getTime(this.$props.trade?.arbitratorCallDate)}`,
        },
      ].filter((e) => {
        return !e.hide;
      });

      const nonZeroProps = [
        {
          key: this.$t('Seller fee'),
          value: `${this.$props.trade.cryptoSellerFee} ${this.getP2PCurrencyById(this.$props.trade?.cryptoSellerCurrencyId)?.title}`,
        },
        {
          key: this.$t('Seller profit'),
          value: `${this.$props.trade.cryptoSellerProfit} ${this.getP2PCurrencyById(this.$props.trade?.cryptoSellerCurrencyId)?.title}`,
        },
        {
          key: this.$t('Buyer fee'),
          value: `${this.$props.trade.cryptoBuyerFee} ${this.getP2PCurrencyById(this.$props.trade?.cryptoSellerCurrencyId)?.title}`,
        },
        {
          key: this.$t('Buyer profit'),
          value: `${this.$props.trade.cryptoBuyerProfit} ${this.getP2PCurrencyById(this.$props.trade?.cryptoSellerCurrencyId)?.title}`,
        },
      ].filter((prop) => prop.value !== 0);

      if (process.env.VUE_APP_P2PAY_VERSION === '3') {
        return [...defaultData, ...nonZeroProps,
          {
            key: this.$t('Client UID'),
            value: this.$props.trade?.ClientUid,
            isLong: true,
          },
        ];
      }

      return [
        ...defaultData,
        ...nonZeroProps,
      ];
    },
  },
};
