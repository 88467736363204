//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';

import widget from '@/components/arbitrator/Common/Widget.vue';
import User from '~/components/User.vue';

export default {
  components: {
    widget,
    User,
  },

  props: {
    arbitrator: { type: Array, default: () => ({}) },
    loader: { type: Boolean, default: false },
    title: { type: String, default: '' },
    tradeId: { type: Number, default: 0 },
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    isDataEmpty() {
      return this.$props.arbitrator.arbitratorId === 0
      && this.$props.arbitrator.arbitratorUsername === ''
      && this.$props.arbitrator.joinDate === 0;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('arbitratorCalls', {
      joinAction: 'join',
      assignAction: 'assign',
    }),
    becomeArbitrator(id = this.$props.tradeId) {
      this.confirmAction({
        title: 'Are you sure, you want take this trade under your arbitration',
        callback: async () => {
          this.setGeneralLoader(true);
          try {
            await this.joinAction(id);
            await this.assignAction(id);
            this.setSuccessNotification('You have successfuly took this trade under your arbitration');
            this.$router.push({ name: 'arbitrator-my-arbitration' });
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralLoader(false);
        },
      });
    },
  },
};
