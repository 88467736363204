//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import widget from '@/components/arbitrator/Common/Widget.vue';

export default {
  components: {
    widget,
  },

  props: {
    items: { type: Array, required: true },
    current: { type: [Number, String], required: true },
    select: { type: Function, required: true },
    loader: { type: Boolean, default: false },
    assign: { type: Function, required: false },
    hasAssignButton: { type: Boolean, default: false },
    showArbitrator: { type: Boolean, default: false },
    showStatus: { type: Boolean, default: false },
  },

  methods: {
    getSellerUsername(item) {
      return item.cryptoSeller?.username;
    },

    getBuyerUsername(item) {
      return item.cryptoBuyer?.username;
    },

    isSellerCalledArbitrator(item) {
      return item.cryptoSeller?.username === item.userCalledArbitratorUsername;
    },

    isBuyerCalledArbitrator(item) {
      return item.cryptoBuyer?.username === item.userCalledArbitratorUsername;
    },

    getArbitratorUsername(item) {
      return item.arbitratorsJoinHistory?.[0]?.arbitratorUsername ?? this.$t('Unknown Arbitrator');
    },

    isActive(item) {
      return item.id === Number(this.$route.query.tradeId);
    },
  },
};

